import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "E-Mail-Vorlagen",
  "description": "Erstellen einer E-Mail Vorlage für das Versenden von E-Mails über das Portal. So gehen Sie vor.",
  "author": "Oliver Lorenz",
  "categories": ["settings"],
  "date": "2021-08-27T00:00:00.000Z",
  "featured": false
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Sie befinden sich auf der Startseite und klicken auf "Einstellungen".`}</p>
    <img src="/images/settings/emailtemplates01.png" alt="email vorlagen" style={{
      "width": "50%"
    }} className="image" />
 
    <p>{`Unter "Einstellungen" finden Sie "E-Mail-Vorlagen". `}</p>
    <p>{`Hier werden Ihnen bereits erstellte Vorlagen angezeigt. Falls keine Vorhanden sind ist dieser Bereich leer. `}</p>
 <img src="/images/settings/emailtemplates02.png" alt="email vorlagen" style={{
      "width": "100%"
    }} />
    <p>{`Zum Erstellen einer neuen Vorlage klicken Sie auf "Neu".`}</p>
 <img src="/images/settings/emailtemplates03.png" alt="email vorlagen" style={{
      "width": "50%"
    }} className="image" />
    <p>{`Es öffnet sich die Seite "E-Mail-Vorlage erstellen".  `}</p>
    <img src="/images/settings/emailtemplates04.png" alt="email vorlagen" style={{
      "width": "100%"
    }} />
    <p>{`Alle relevanten Felder müssen ausgefüllt werden.`}</p>
    <img src="/images/settings/emailtemplates05.png" alt="email vorlagen" style={{
      "width": "100%"
    }} />
    <p>{`Um die Vorlage zu speichern und bereitzustellen müssen Sie im gleichen Fenster nach unten scrollen und auf den Button "E-Mail-Vorlage erstellen" klicken. `}</p>
    <img src="/images/settings/emailtemplates06.png" alt="email vorlagen" style={{
      "width": "100%"
    }} />
    <p>{`Die E-Mail-Vorlage ist nun verfügbar.`}</p>
    <img src="/images/settings/emailtemplates07.png" alt="email vorlagen" style={{
      "width": "100%"
    }} />
    <p>{`Um die Vorlage zu nutzen, können Sie nun einen Bewerber öffnen und auf "E-Mail senden" klicken.`}</p>
 <img src="/images/settings/emailtemplates08.png" alt="email vorlagen" style={{
      "width": "100%"
    }} />
    <p>{`Es öffnet sich nun das Fenster "E-Mail schreiben". `}</p>
    <img src="/images/settings/emailtemplates09.png" alt="email vorlagen" style={{
      "width": "100%"
    }} />
    <p>{`Unter "Vorlage" kann nun die erstellte Vorlage ausgewählt werden. `}</p>
    <img src="/images/settings/emailtemplates10.png" alt="email vorlagen" style={{
      "width": "100%"
    }} />
    <p>{`Der Text aus der Vorlage wird nun über der Signatur eingefügt. `}</p>
    <img src="/images/settings/emailtemplates11.png" alt="email vorlagen" style={{
      "width": "100%"
    }} />
    <p>{`Die E-Mail kann über "E-Mail senden" an den Bewerber verschickt werden.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      